import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | EcoDrive Service Center
			</title>
			<meta name={"description"} content={"EcoDrive Service Center – сучасна станція техобслуговування авто, яка спеціалізується на встановленні та обслуговуванні систем ГБО."} />
			<meta property={"og:title"} content={"Home | EcoDrive Service Center"} />
			<meta property={"og:description"} content={"EcoDrive Service Center – сучасна станція техобслуговування авто, яка спеціалізується на встановленні та обслуговуванні систем ГБО."} />
			<link rel={"shortcut icon"} href={"https://arataplesta.com/imgs/6456819.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileImage"} content={"https://arataplesta.com/imgs/6456819.png"} />
			<meta name={"msapplication-TileColor"} content={"https://arataplesta.com/imgs/6456819.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="0 0 0 0" quarkly-title="Hero-7">
			<Override slot="SectionContent" width="100%" flex-direction="row" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				justify-content="space-around"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="120px 50px 120px 50px"
					lg-width="100%"
					lg-padding="80px 50px 90px 50px"
					sm-padding="80px 25px 90px 25px"
					lg-display="flex"
					lg-flex-direction="column"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						EcoDrive Service Center{"\n\n"}
					</Text>
					<Text margin="0px 0px 50px 0px" font="--lead" color="#8b9197">
						ЕcoDrive Service Center – місце, де ви можете не тільки обслужити свій автомобіль, але й дізнатися більше про зелені технології та методи заощадження пального. 

Наша місія – зробити ваше авто більш екологічним та ефективним, вашу подорож безпечною та комфортною, зменшуючи при цьому викиди шкідливих речовин у повітря та економлячи вам гроші на пальному.

Наша команда фахівців відома своєю експертизою в області установки та обслуговування систем ГБО, що дозволяють автомобілям переходити на більш екологічне пальне та зменшити викиди CO2. EcoDrive Service Center завжди готова допомогти вам зробити ваш автомобіль більш зручним, економічним та екологічно чистим. Drive Service Center – місце, де ви можете не тільки обслужити свій автомобіль, але й дізнатися більше про зелені технології та методи заощадження пального.{"\n"}
						<br />
						<br />
						Наша місія – зробити ваше авто більш екологічним та ефективним, вашу подорож безпечною та комфортною, зменшуючи при цьому викиди шкідливих речовин у повітря та економлячи вам гроші на пальному.{"\n"}
						<br />
						<br />
						Наша команда фахівців відома своєю експертизою в області установки та обслуговування систем ГБО, що дозволяють автомобілям переходити на більш екологічне пальне та зменшити викиди CO2. "EcoDrive Service Center" завжди готова допомогти вам зробити ваш автомобіль більш зручним, економічним та екологічно чистим.{"\n\n\n"}
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="#3f24d8"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							transition="background-color 0.2s ease 0s"
							border-width="2px"
							border-style="solid"
							border-color="#3f24d8"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							type="link"
							text-decoration-line="initial"
							href="/contact-us"
						>
							Зв'язатися
						</Button>
					</Box>
				</Box>
				<Image
					src="https://arataplesta.com/imgs/denys-nevozhai-7nrsVjvALnA-unsplash.jpg"
					display="block"
					width="50%"
					object-fit="cover"
					height="100%"
					lg-width="100%"
					lg-height="700px"
					sm-height="500px"
				/>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-1">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="center"
				lg-width="100%"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 100% 0px"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						src="https://arataplesta.com/imgs/pexels-jay-pizzle-3894030.jpg"
						position="absolute"
						display="block"
						width="100%"
						left={0}
						min-height="100%"
						object-fit="cover"
						top={0}
						right={0}
						bottom={0}
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
				flex-direction="column"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						src="https://arataplesta.com/imgs/lemon-pepper-pictures-HA7e2KX3tqg-unsplash.jpg"
						top={0}
						display="block"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Наша станція техобслуговування пропонує послуги, спрямовані на поліпшення якості експлуатації автомобілів і споживання пального, а також на зменшення впливу транспорту на довкілля. Проте "EcoDrive Service Center" - це не тільки про ГБО. Ми також забезпечуємо широкий спектр послуг для збереження, підтримки та модернізації вашого автомобіля.

Наша команда завжди на сторожі вашої безпеки та зручності на дорозі.
				</Text>
			</Box>
		</Section>
		<Components.Advantages />
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6523c308e2dd3a0022204417"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});